<template>
  <section class="bg-white px-3 py-2 rounded mb-4">
    <form @submit="formSubmit" enctype="multipart/form-data">
      <b-form-file
        v-model="csv_file"
        :state="input_field_state"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
        accept=".csv"
        ></b-form-file>
        <b-button :disabled="isDisabled" variant="primary" class="mt-2" type="submit">
            Upload & Continue
        </b-button>
    </form>
  </section>
</template>

<script>
  import { BRow, BCol, BFormSelect, BButton, BFormFile } from "bootstrap-vue";
  import QuestionnaireMenu from '../../components/questionnaire/QuestionnaireMenu.vue';
  import NewQuestion from '../../components/questionnaire/NewQuestion.vue';
  import SetupQuestionnaireDetails from '../../components/questionnaire/SetupQuestionnaireDetails.vue';
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

  export default {
    components: {
      BRow,
      BCol,
      BFormSelect,
      BButton,
      BFormFile,
      QuestionnaireMenu,
      NewQuestion,
      SetupQuestionnaireDetails
    },
    data() {
      return {
        csv_file: null,
        input_field_state: null,
        disabled: false
      }
    },
    computed: {
        isDisabled() {
          if(this.csv_file == null || this.disabled == true) {
            return true
          } else {
            this.input_field_state = true;
            return false
          }
        }
    },
    methods: {
        formSubmit(e) {//test
            e.preventDefault();
            let existingObj = this;

            this.disabled = true

            const config = {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }

            let data = new FormData();
            data.append('file', this.csv_file);

            this.$http.post('/api/auth/admin/questionnaire/upload/csv', data, config)
                .then(function (res) {
                  existingObj.success = res.data.success;
                    
                    existingObj.$toast({
                      component: ToastificationContent,
                        props: {
                          title: "CSV is being processed...",
                            icon: "InfoIcon",
                            variant: "info",
                        },
                    });

                    existingObj.disabled = false

                    existingObj.$router.push({ name: 'home' })
                })
                .catch(function (err) {
                    existingObj.output = err;
                    let errMsg = "Something went wrong, please try again"

                    if(err.response.data != undefined) {
                      if(err.response.data.message != undefined) {
                        errMsg = err.response.data.message
                      }
                    }
                    
                    existingObj.$toast({
                        component: ToastificationContent,
                        props: {
                            title: errMsg,
                            icon: "AlertCircleIcon",
                            variant: "danger",
                        },
                    });

                    existingObj.disabled = false

                });
        }
    }
  }
</script>
