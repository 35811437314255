<template>
  <section class="bg-white p-2 rounded mb-4">
    <b-row>
      <b-col md="4">
        <b-row no-gutters>
            <h4 class="mb-2">SETUP QUESTIONNAIRE</h4>
            <b-button
                block
                variant="mhc-dark"
                size="md"
                class="text-left">
                Questionnaire details
            </b-button>
        </b-row>
      </b-col>
      
      <b-col md="8" class="pt-2 pt-md-0">
        <div class="px-3">
          <keep-alive>
            <component v-bind:is="component" />
          </keep-alive>
        </div>
      </b-col>
      <b-col md="4" class="pt-2 pt-md-0">
      </b-col>
      <!--
      <b-col md="8" class="pt-2 pt-md-0">
        <upload-questionnaires-csv></upload-questionnaires-csv>
      </b-col>
      -->
    </b-row>
  </section>
</template>

<script>
  import store from "@/store/index";
  import { BRow, BCol, BFormSelect, BButton } from "bootstrap-vue";
  import QuestionnaireMenu from '../../components/questionnaire/QuestionnaireMenu.vue';
  import NewQuestion from '../../components/questionnaire/NewQuestion.vue';
  import SetupQuestionnaireDetails from '../../components/questionnaire/SetupQuestionnaireDetails.vue';
  import UploadQuestionnairesCsv from '../questionnaire/UploadQuestionnairesCSV.vue';

  export default {
    components: {
      BRow,
      BCol,
      BFormSelect,
      BButton,
      QuestionnaireMenu,
      NewQuestion,
      SetupQuestionnaireDetails,
      UploadQuestionnairesCsv
    },

    computed: {
      component() {
        return store.state.questionnaireStoreModule.questionnaireComponent;
      }
    },

    data() {
      return {
      }
    },

    mounted() {
      store.commit('questionnaireStoreModule/setQuestionnaireComponent', 'SetupQuestionnaireDetails')
    },

    methods: {

    }
  }
</script>

<style scoped>

</style>
