<template>
    <div>
        <validation-observer ref="questionValidation">
            <b-form class="auth-login-form mt-2" @submit.prevent>
                <small>Select question type</small>
                <validation-provider
                    #default="{ errors }"
                    name="question section"
                    rules="required"
                >
                    <b-form-group
                        label="Question section"
                        label-for="questionType"
                        :state="errors.length > 0 ? false:null"
                    >
                        <b-form-select v-model="question.question_type_id" >
                            <b-form-select-option v-for="question_type in questionTypes" 
                                :value="question_type.id" :key="question_type.id" 
                                :disabled="question_type.name.includes('Section C') || question_type.name.includes('Section A')" >
                                {{ question_type.name }}
                            </b-form-select-option>
                        </b-form-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                </validation-provider>
                <hr>

                <small>Question text</small>
                <validation-provider
                    #default="{ errors }"
                    name="question text"
                    rules="required">
                    <b-form-input v-model="question.question_text" :state="errors.length > 0 ? false : null" placeholder="Enter question text"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                
                <!--
                <small>Select response scale - always section B for now (Likert scale)</small>
                <b-form-select disabled v-model="selectedResponseScale" :options="optionsResponseScale"></b-form-select>                                
                -->
                <div>
                    <b-button
                        v-if="this.question.questionnaire_id != undefined || checkIfQuestionTypeIsSectionB(this.question.question_type_id) == true"
                        variant="mhc-dark"
                        size="md"
                        class="text-center mt-2"
                        @click="validateForm()">
                        <template v-if="question.questionnaire_id != undefined">
                            Add question
                        </template>
                        <template v-else>
                            Update question
                        </template>
                    </b-button>
                </div>
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
import store from "@/store/index";
import { BButton, BFormSelect, BFormInput, BFormSelectOption, BForm, BFormGroup } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import EventBus from '../../main.js';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
    components: {
        BFormSelect,
        BFormInput,
        BFormSelectOption,
        BButton,
        BForm,
        ValidationProvider,
        ValidationObserver,
        BFormGroup
    },

    data() {
        return {
            // Question types
            questionTypes: [],

            // Select question type
            selectedQuestionType: null,
            optionsQuestionType: [],

            // Question text
            questionText: '',

            // Select response scale
            selectedResponseScale: null,
            optionsResponseScale: [],
            required,
        }
    },
    props: ['questionnaireType'],

    computed: {
        question() {
            return store.state.questionnaireStoreModule.question;
        }
    },

    mounted() {
        // Get question types
        this.$http.get(
            "/api/auth/admin/question/types/" + this.$route.params.id
        )
        .then((res) => {
            if(res != undefined) {
                this.questionTypes = res.data
            }
        })
        .catch((err) => {
            console.log(err)
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Something went wrong, please login again",
                    icon: "AlertCircleIcon",
                    variant: "danger",
                },
            });
        })
    },

    methods: {
        checkIfQuestionTypeIsSectionB(question_type_id) {
            for(let i = 0; i < this.questionTypes.length; i++) {
                if(this.questionTypes[i].id == question_type_id) {
                    if(this.questionTypes[i].name.includes("Section B")) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        },
        validateForm() {
            this.$refs.questionValidation.validate().then((success) => {
                if (success) {
                    this.addQuestion();
                }
            });
        },
        addQuestion() {
            /**
             * Some values are hardcoded because for now admins will add only section B (Liker scale) questions
             * Other questions for sections A and C are added automatically to the questionnaire on the backend side when it is created 
             */
            let question_l = {
                "question_text": this.question.question_text,
                "multiple_answers": 0, // HARDCODED FOR NOW
                "standard_question": 1, // HARDCODED FOR NOW
                "question_type_id": this.question.question_type_id,
                "is_scale": 1 // HARDCODED FOR NOW             
            }

            let urlToCall = "";
            let message = "";
            
            // if there is a questionnaire_id param on the question object, add it to the question object here
            // CASE WHEN ADDING A NEW QUESTION TO THE QUESTIONNAIRE
            if(this.question.questionnaire_id != undefined) {
                question_l.questionnaire_id = this.question.questionnaire_id;
                urlToCall = "/api/auth/admin/questionnaire/question";
                message = "Successfully added question!"
            } else {
                // else, since the question object is passed as a whole, get the id
                // CASE WHEN UPDATING A QUESTION THAT IS ALREADY ADDED TO THE QUESTIONNAIRE
                question_l.questionnaire_id = this.question.id;
                urlToCall = "/api/auth/admin/questionnaire/question/" + this.question.id;                 
                message = "Successfully updated question!"
            }
            
            this.$http.patch(urlToCall, question_l)
            .then((res) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: message,
                        icon: "CheckSquareIcon",
                        variant: "success",
                    },
                });
                // if we are adding a question, we need to trigged closing the modal and refresh of questionnaire questions
                EventBus.$emit('closeTheModal');
                // emit the questionnaireQuestionUpdated event so that the preview gets updated too
                EventBus.$emit('questionnaireQuestionsUpdated');

                EventBus.$emit('getFreshQuestionnaireQuestions');

            })
            .catch((error) => {
                console.log(error)
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "There was an error!",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });
            });

        }
    },
}
</script>

<style scoped>
    option[disabled] { background-color: #D0D0D0; }
</style>