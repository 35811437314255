<template>
    <div class="h1 mb-0 h-100">
        <div class="d-flex flex-column h-100">
            <b-row no-gutters>
                <h4 class="mb-2">SETUP QUESTIONNAIRE</h4>
                <b-button
                    block
                    variant="mhc-dark"
                    size="md"
                    class="text-left"
                    @click="changeComponent('SetupQuestionnaireDetails')">
                    Questionnaire details
                </b-button>
            </b-row>

            <b-row no-gutters class="mt-3">
                <h4>QUESTIONNAIRE QUESTIONS</h4>
                <b-form-group
                    class="mb-0"
                    id="input-group-1"
                    label="Search questions:"
                    label-for="input-1"
                    description="Search questions by question text or question section"
                >
                    <b-form-input
                        style="margin-bottom: 10px;"
                        id="input-1"
                        v-model="searchQuestion"
                        type="text"
                        placeholder="Enter search term"
                        required
                    ></b-form-input>
                    <b-form-select v-model="questionType" @change="refilterQuestions">
                        <b-form-select-option value="all">
                            Display all 
                        </b-form-select-option>
                        <b-form-select-option v-for="question_type in questionTypes" 
                            :value="question_type.name" 
                            :key="question_type.id">
                            {{ question_type.name }}
                        </b-form-select-option>
                    </b-form-select>
                </b-form-group>
                <div class="col col-12 mt-1" style="height: 42vh; overflow-y: scroll">
                    <div class="row mt-1 no-gutters" v-for="(q, index) in filteredList" :key="q.id">
                        <div class="col col-10">                            
                            <b-button
                                :id="'btn-' + index"
                                block
                                variant="mhc-dark"
                                size="md"
                                class="text-left"
                                @click="changeQuestion(q)">
                                <small class="mb-2">
                                    <u>{{ q.type.name }}</u>
                                </small><br><br>
                                Q# {{ (index + 1) }} - <span v-html="$options.filters.returnFirstSixWords($options.filters.strippedHTML(q.question_text))"></span>
                            </b-button>
                            <b-tooltip :target="'btn-' + index" placement="left" html="true" delay="500" triggers="hover">
                                {{ q.question_text | strippedHTML }}
                            </b-tooltip>
                        </div>
                        <div class="col col-2 text-center align-items-center d-flex justify-content-center">
                            <b-button :disabled="isDeleteBtnDisabled(q)" variant="outline-danger" class="btn-icon rounded-circle" @click="deleteQuestion(q)">
                                <feather-icon icon="Trash2Icon" size="18" />
                            </b-button>
                        </div>
                    </div>
                </div>
            </b-row>
            <b-row no-gutters class="mt-auto pt-3 justify-content-between">
                <b-button @click="openQuestionModal()" class="mt-1" :disabled="isAddBtnDisabled()">Add question</b-button>
                <b-button @click="changeComponent('PreviewQuestionnaire')" class="mt-1">Preview Questionnaire</b-button>
            </b-row>
        </div>
        <b-modal id="add-question" ref="add-question" title="Add a question to the questionnaire" centered hide-footer @hide="resetQuestionData();">
            <new-question :questionnaireType="questionnaireType" />
        </b-modal>
    </div>
</template>

<script>
import store from "@/store/index";
import { BRow, BCol, BButton, BTooltip, BFormGroup, BFormInput, BFormSelect, BFormSelectOption } from "bootstrap-vue";
import NewQuestion from './NewQuestion.vue';
import EventBus from '../../main.js';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BRow,
        BCol,
        BButton,
        NewQuestion,
        BTooltip, 
        BFormGroup, 
        BFormInput,
        BFormSelect,
        BFormSelectOption
    },

    filters: {
        returnFirstSixWords: function(string) {      
            if(string.trim().split(" ").length > 11) {
                return string.trim().split(" ").slice(0, 11).join(" ") + " ...";
            } else {
                return string.trim().split(" ").slice(0, 11).join(" ");
            }
        }
    },
        
    data() {
        return {
            questionnaire: {
                questions: []
            },
            boxTwo: '',
            searchQuestion: '',
            questionTypes: [],
            questionType: 'all',

            questionnaireType: 1,
            questionnaireCategory: null
        }
    },

    methods: {

        isDeleteBtnDisabled(question) {
            if(this.questionnaireCategory == "My mental health and my life situation(s)") {
                if(question.type.name == "Section A" || question.type.name.includes("Section C")) {
                    return true;
                } 
                return false;
            } else if(this.questionnaireCategory == "My mental health and how I feel" || this.questionnaireCategory == "My mental health and my job/career") {
                return false;
            }
            return true;
        },

        isAddBtnDisabled() {
            if(
                this.questionnaireCategory == "My mental health and my life situation(s)" || 
                this.questionnaireCategory == "My mental health and how I feel" || 
                this.questionnaireCategory == "My mental health and my job/career") {
                return false;
            }
            return true;
        },

        // Get question types
        getQuestionTypes() {
            this.$http.get(
                "/api/auth/admin/question/types/" + this.$route.params.id
            )
            .then((res) => {
                if(res != undefined) {
                    this.questionTypes = res.data
                }
            })
            .catch((err) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Something went wrong, please login again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });
            })
        },

        // Change Component 
        changeComponent(component) {
            store.commit("questionnaireStoreModule/setQuestionnaireComponent", component);
        },

        // Change 
        changeQuestion(question) {
            this.changeComponent('NewQuestion')
            store.commit("questionnaireStoreModule/setQuestion", question);
        },

        openQuestionModal() {
            this.changeComponent('SetupQuestionnaireDetails')
            let question = {
                "question_type_id" : null,
                "question_text" : "",
                "questionnaire_id": this.$route.params.id
            }
            store.commit("questionnaireStoreModule/setQuestion", question);
            this.$refs['add-question'].show();
        },

        resetQuestionData() {
            this.getQuestionnaireQuestions();
            this.changeComponent('NewQuestion')
        },

        getQuestionnaireQuestions() {
            this.$http.get("/api/auth/admin/questionnaires/" + this.$route.params.id)
            .then((response) => {
                this.questionnaire.questions = response.data.questions
                this.questionnaireCategory = response.data.subcategory.questionnairecategory.name
            }).catch((error) => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "There was an error!",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                 });
            });
        },

        deleteQuestion(question) {
            this.boxTwo = ''
            this.$bvModal
                .msgBoxConfirm('Please confirm that you want to delete this question.', {
                title: 'Please Confirm',
                size: 'sm',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-secondary',
                hideHeaderClose: false,
                centered: true,
            }).then(value => {        
                if(value == true) {
                    this.$http.delete("/api/auth/admin/questionnaire/question/" + question.id + "/" + this.$route.params.id)
                    .then((response) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "The question was successfully deleted!",
                                icon: "CheckSquareIcon",
                                variant: "success",
                            },
                        });
                        EventBus.$emit('questionnaireQuestionsUpdated');
                        this.getQuestionnaireQuestions();
                    }).catch((error) => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: "There was an error deleting the question!",
                                icon: "AlertCircleIcon",
                                variant: "danger",
                            },
                        });
                    });
                }
            })
        },
        refilterQuestions(val) {
            this.computeQuestions();
        },
        computeQuestions() {
            // if the user selected a section, take than into account
            if(this.questionType != "all") {
                let questions = [];
                for(let i = 0; i < this.questionnaire.questions.length; i++) {
                    if(this.questionnaire.questions[i].type.name.toLowerCase().includes(this.questionType.toLowerCase())) {
                        questions.push(this.questionnaire.questions[i]);
                    }
                }
                return questions.filter(question => {
                    return question.question_text.toLowerCase().includes(this.searchQuestion.toLowerCase())
                });
            } 
            // else just filter by the search term
            return this.questionnaire.questions.filter(question => {
                return question.question_text.toLowerCase().includes(this.searchQuestion.toLowerCase())
            });
        }
    },
    mounted() {
        this.getQuestionnaireQuestions();
        this.getQuestionTypes();
        // when the closeTheModal event is fired in the NewQuestion component, close the modal
        // and therefore refresh the questions list
        var temp = this;
        EventBus.$on('closeTheModal', () => {
            if(this.$refs['add-question'] != undefined) {
                this.$refs['add-question'].hide();  
                temp.getQuestionnaireQuestions();
                temp.changeComponent('SetupQuestionnaireDetails')
            }
        });

        // Event to get fresh questionnaire questions
        EventBus.$on('getFreshQuestionnaireQuestions', () => {
            this.getQuestionnaireQuestions();
        });

        // Event to detect if questionnaire type changed on SetupQuestionnaireDetails page
        EventBus.$on('getFreshSelectedQuestionnaireType', (questionnaireType) => {
            // Get fresh data for modal
            this.questionnaireType = questionnaireType
        });

    },

    computed: {
        filteredList() {
            return this.computeQuestions();          
        }
    }
    
}

</script>

<style scoped>

</style>