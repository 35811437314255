<template>
  <validation-observer ref="questionnaireRules">
    <div class="h-100 d-flex flex-column">    
      
      <div class="mt-2">
        <small>Select questionnaire category</small>
        <b-form-select
        :disabled="currentRoute == 'questionnaire-single'"
          v-model="selectedQuestionnaireCategory"
          @change="questionnaireCategoryChanged"
        >
          <b-form-select-option v-for="option in optionsQuestionnaireCategory" :value="option">
            {{ option.value.name }}
          </b-form-select-option>
        </b-form-select>
      </div>
      
      <div class="mt-2" v-if="selectedQuestionnaireCategory.value.name == 'My mental health and how I feel'">
        <small>Select questionnaire subcategory</small>
        <b-form-select
          :disabled="currentRoute == 'questionnaire-single'"
          v-model="selectedQuestionnaireSubcategory"
          :options="optionsQuestionnaireSubcategory"
        ></b-form-select>
      </div>

      <div class="mt-2">
        <small>Questionnaire title</small>
        <validation-provider
          #default="{ errors }"
          name="Questionnaire title"
          rules="required"
        >
          <b-form-input v-model="questionnaireTitle" :state="errors.length > 0 ? false : null" placeholder="Enter questionnaire title"></b-form-input>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </div>

      <div class="mt-2">
        <small>Questionnaire description</small>        

          <b-form-textarea
            v-model="questionnaireDescription"
            placeholder="Enter something..."
            rows="3"
            max-rows="6"
          ></b-form-textarea>
      </div>

      <div class="mt-2">
        <small>Select professional</small>
        <b-form-select
          v-model="selectedProfessional"
          :options="optionsProfessional"
        ></b-form-select>
      </div>

      <div class="mt-2" v-if="currentRoute == 'questionnaire-single'">
        <small>Available</small>
        <b-form-checkbox
          v-model="available"
          class="custom-control-mhc-dark"
          name="check-button"
          switch
        />
      </div>

      <div class="mt-2">
        <small>Select special message</small>
        <b-form-select
          v-model="selectedSpecialMessage"
          :options="optionsSpecialMessage"
        ></b-form-select>
      </div>

      <div class="mt-2">
        <small>Anxiety threshold</small>
        <validation-provider
          #default="{ errors }"
          name="Anxiety threshold"
          rules="required"
        >
          <b-form-input
            type="number"
            v-model="anxiety_threshold"
            placeholder="Enter Anxiety threshold"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </div>

      <div class="mt-2">
        <small>Depression threshold</small>
        <validation-provider
          #default="{ errors }"
          name="Depression threshold"
          rules="required"
        >
          <b-form-input
            type="number"
            v-model="depression_threshold"
            placeholder="Enter Depression threshold"
          />
        <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </div>

      <div class="mt-auto pt-2">
        <b-button variant="primary" class="mr-2" @click="saveDialog"
          >Save & Continue</b-button
        >
        <b-button variant="secondary" @click="openMessages" v-if="this.currentRoute == 'questionnaire-single'"
          >Messages</b-button
        >
      </div>
    </div>
  </validation-observer>
</template>

<script>
import EventBus from '@/main';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { BRow, BButton, BFormInput, BFormTextarea, BFormSelect, BFormCheckbox, BFormSelectOption } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BFormSelect,
    BFormTextarea,
    BButton,
    BFormInput,
    BFormCheckbox,
    BFormSelectOption
  },

  data() {
    return {
      currentRoute: '',
      required,

      selectedCategory: 1,

      // Questionnaire Category
      selectedQuestionnaireCategory: {
        "value": 0,
        "name": ""
      },
      optionsQuestionnaireCategory: [],

      // Questionnaire Subcategory
      selectedQuestionnaireSubcategory: null,
      optionsQuestionnaireSubcategory: [],

      // Questionnaire title
      questionnaireTitle: "",

      // Questionanaire description
      questionnaireDescription: "",

      // Professional profiles
      selectedProfessional: null,
      optionsProfessional: [],

      // Available
      available: false,

      // Special Message
      selectedSpecialMessage: null,
      optionsSpecialMessage: [],

      //Thresholds
      anxiety_threshold: 50,
      depression_threshold: 55,
    };
  },
  methods: {

    getQuestionnaireCategories() {
      this.$http.get("/api/questionnaire-category")
      .then((res) => {
        if (res != undefined) {
          let questionnaireCategories = [];
          for (let i = 0; i < res.data.length; i++) {
            questionnaireCategories.push({
              value: res.data[i],
              text: res.data[i].name,
            });
          }
          this.optionsQuestionnaireCategory = questionnaireCategories;
          this.selectedQuestionnaireCategory = this.optionsQuestionnaireCategory[0];
        }
      }).then(() => {
            // Get questionnaire subcategories
            this.getQuestionnaireSubcategories()
        })
      .catch((err) => {
        console.log(err);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Something went wrong, please login again",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      });
    },
    
    getQuestionnaireSubcategories() {
      this.$http.get("/api/auth/admin/questionnaire/subcategories/" + this.selectedQuestionnaireCategory.value.id)
      .then((res) => {
        if (res != undefined) {
          let questionnaireSubcategories = [];

          for (let i = 0; i < res.data.length; i++) {
            questionnaireSubcategories.push({
              value: res.data[i].id,
              text: res.data[i].name,
            });
          }

          this.optionsQuestionnaireSubcategory = questionnaireSubcategories;
        }
      })
      .catch((err) => {
        console.log(err);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Something went wrong, please login again",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      });
    },

    questionnaireCategoryChanged() {
      this.getQuestionnaireSubcategories()
    },

    getProfessionalProfiles() {
      // Get professionals
      this.$http.get("/api/auth/admin/professionals?listAll=true")
        .then((res) => {
          if (res != undefined) {

            let professionals = [{
              value: null,
              text: 'None'
            }]

            for (let i = 0; i < res.data.length; i++) {
              professionals.push({
                value: res.data[i].id,
                text: res.data[i].first_name + ' ' + res.data[i].last_name + ' - ' + res.data[i].qualifications,
              });
            }

            this.optionsProfessional = professionals;
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Something went wrong, please login again",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },
    saveDialog() {
      if (this.currentRoute == "questionnaire-create") {
        this.saveQuestionnaire()
      } else {
        this.saveQuestionnaire()
        EventBus.$emit('getFreshQuestionnaireQuestions')
      }
    },
    saveQuestionnaire() {
      this.$refs.questionnaireRules.validate().then((success) => {
        // Validation - title is required
        if (success) {
          let questionnaireId = this.$route.params.id;

          let params = {
            title: this.questionnaireTitle,
            description: this.questionnaireDescription,
            available: this.available,
            special_message: this.selectedSpecialMessage,
            anxiety_threshold: this.anxiety_threshold,
            depression_threshold: this.depression_threshold,
            questionnaire_category_id: this.selectedQuestionnaireCategory.value.id,
            professional_id: this.selectedProfessional,
          };

          if(this.selectedQuestionnaireCategory.value.name == 'My mental health and how I feel') {
            params.questionnaire_subcategory_id = this.selectedQuestionnaireSubcategory
          }

          if (this.currentRoute == "questionnaire-create") {
            // Create new questionnaire
            
            // because the questionnaire is new, set the available form field to false
            params.available = false;
            
            this.$http.post("/api/auth/admin/questionnaire", params)
              .then((res) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Successfully created",
                    icon: "EditIcon",
                    variant: "success",
                  },
                });

                this.$router.push("/questionnaire-single/" + res.data.id);
              })
              .catch((err) => {
                this.$toast({
                  component: ToastificationContent,
                    props: {
                      title: err.response.data.message,
                      icon: "AlertCircleIcon",
                      variant: "danger",
                  },
                });
              });
          } else {
            // Update existing questionnaire
            this.$http
              .patch("/api/auth/admin/questionnaire/" + questionnaireId, params)
              .then((res) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Successfully updated",
                    icon: "EditIcon",
                    variant: "success",
                  },
                });
              })
              .catch((err) => {
                this.$toast({
                  component: ToastificationContent,
                    props: {
                      title: err.response.data.message,
                      icon: "AlertCircleIcon",
                      variant: "danger",
                  },
                });
              });
          }
        }
      });
    },

    openMessages() {
      this.$router.push({name: 'questionnaire-messages'})
    },

    getQuestionnaireData() {
      // Get questionnaire data
      if (this.currentRoute == "questionnaire-single") {
        let questionnaireId = this.$route.params.id;

        this.$http.get("/api/auth/admin/questionnaires/" + questionnaireId)
          .then((res) => {
            if (res != undefined) {
              
              this.optionsQuestionnaireCategory.forEach(element => {
                if(element.value.id == res.data.subcategory.questionnairecategory.id) {
                  this.selectedQuestionnaireCategory = element
                }
              })
              
              this.selectedQuestionnaireSubcategory = res.data.subcategory.id;
              this.questionnaireTitle = res.data.title;
              this.questionnaireDescription = res.data.description;

              // Available translate to true/false
              if(res.data.available == 1 ? this.available = true : this.available = false)

              this.selectedSpecialMessage = res.data.special_message;
              this.anxiety_threshold = res.data.anxiety_threshold;
              this.depression_threshold = res.data.depression_threshold;
              this.selectedProfessional = res.data.professional_id;

            }
          })
          .then(() => {
            // Get professional profile
            this.getProfessionalProfiles()
            this.getQuestionnaireSubcategories()
          })
          .catch((err) => {
            console.log(err)
            this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Something went wrong, please login again",
                  icon: "AlertCircleIcon",
                  variant: "danger",
                },
            });
          });
      }
    }
  },

  mounted() {
    this.currentRoute = this.$route.name

    // Get special messages
    this.$http.get("/api/auth/admin/questionnaire/special-message")
      .then((res) => {
        if (res != undefined) {
          this.optionsSpecialMessage = res.data;
        }
      })
      .catch((err) => {
        console.log(err);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Something went wrong, please login again",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      });  
    

    EventBus.$on('questionnaireQuestionsUpdated', () => {
      this.getQuestionnaireData();
    });


    this.getProfessionalProfiles()  
    this.getQuestionnaireCategories()
    this.getQuestionnaireData()
  },
};
</script>
